import React from "react"
import { getCourseNavigation } from "../../../store/courses"
import LayoutCourse from "../../../layouts/course"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Paragraph from "../../../components/paragraph"
import CourseFooterNext from "../../../components/course-footer-next"
import Heading from "../../../components/heading"
import Note from "../../../components/note"
import Seo from "../../../components/seo"
import TextLink from "../../../components/textlink"
import Stack from "../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Kurs starten"
          to="/kurse/reiseerinnerung/01-suedfrankreich"
        />
      }
      navigation={navigation}
    >
      <Seo title="Eine Reiseerinnerung wird zum Gedicht" />
      <Stack>
        <Heading as="h2" level={2}>
          Informationen für Lehrer*innen
        </Heading>
        <Paragraph>
          In diesem Kurs beschäftigen sich die Schüler*innen mit Friedrich
          Hölderlins Reisegedicht ›Andenken‹.
        </Paragraph>
        <Paragraph>
          Im{" "}
          <TextLink to="/kurse/reiseerinnerung/01-suedfrankreich">
            Kapitel 1
          </TextLink>{" "}
          suchen sie zunächst nach Formen des Reisens sowie nach Orten und
          Landschaftsbeschreibungen im Gedicht. Dabei erfahren sie über
          Hölderlins Faszination für das Wasser und seine genaue geografische
          Kenntnis der beschriebenen Gegend in Südfrankreich. Sie versuchen die
          Bewegungsrichtungen des Gedichts nachzuzeichnen und gleichen den
          Gedichtbeginn mit einem Bild aus der Ausstellung ›Hölderlins Orte –
          Fotografien von Barbara Klemm‹ ab.
        </Paragraph>
        <Paragraph>
          Die Schüler*innen betrachten Hölderlin im Bezug auf das Reisen und die
          Reiseliteratur seiner Zeit und beschäftigen sich mit den biografischen
          Zeugnissen seiner Fußreise nach Bordeaux im Winter 1801/1802. Die
          gewonnenen Eindrücke gleichen sie mit den Stimmungen des lyrischen
          Ichs im Gedicht ab und entwickeln eine Emoji-Bildergeschichte zum
          Gedicht. Sie wägen Hölderlins Beweggründe für den Aufbruch nach
          Bordeaux ab und entwerfen darauf aufbauend einen Werbetext für ein
          Work & Travel-Programm in Südfrankreich, in den Passagen aus dem
          Gedicht einfließen.
        </Paragraph>
        <Paragraph>
          Von dieser Annäherung über vorgefundene Ortsbezüge und Stimmungen wird
          die Gedichtbetrachtung in{" "}
          <TextLink to="/kurse/reiseerinnerung/02-dichterwerkstatt/">
            Kapitel 2
          </TextLink>{" "}
          auf die sprachliche, formelle und klangliche Gemachtheit des Gedichts
          gelenkt: Die Schüler*innen erforschen das Gedicht im Manuskript,
          suchen nach Struktur- und Kompositionsmerkmalen und erfahren, wie sich
          diese auf die Sprachmelodie des Gedichts auswirken. Anhand von Audio-
          und Videobeiträgen von Schauspielern und Sprechkünstlern setzen sie
          sich mit sprecherischen Interpretationsmöglichkeiten des Gedichts
          auseinander und versuchen daraufhin selbst, das Gedicht ins Gehen und
          Sprechen zu übertragen. Zuletzt machen sie sich mit der Kamera auf die
          Suche nach eigenen bewegten Bildern und Sprechhaltungen für das
          Gedicht.
        </Paragraph>
        <Note title="Hinweis">
          Der Kurs kann sowohl von den Schüler*innen eigenständig bearbeitet als
          auch in den Unterreicht integriert werden. Alle Einheiten sind
          interaktiv angelegt. Sie fördern eine produktive und kreative
          Erschließung literarischer Formen und Texte, sensibilisieren die
          Schüler*innen für die Machart literarischer Texte und tragen zur
          ästhetischen Bildung bei. Die Kapitel 1 und 2 können aufeinander
          aufbauend, aber auch unabhängig voneinander behandelt werden.
        </Note>
        <Paragraph>
          Die Kapitel sind an folgenden Inhalten des Bildungsplans angelehnt:
        </Paragraph>
        <BulletList size={[3, 3, 4, 5]}>
          <Bullet>Methoden der Texterschließung anwenden</Bullet>
          <Bullet>
            eigene Leseeindrücke formulieren, begründen und an Textbelegen
            erläutern
          </Bullet>
          <Bullet>zentrale Motive und Strukturen bestimmen und benennen</Bullet>
          <Bullet>
            literarische Gattungen und Gattungsmerkmale identifizieren
          </Bullet>
          <Bullet>
            Mehrdeutigkeiten erkennen und alternative Lesarten entwickeln
          </Bullet>
          <Bullet>
            Geistes‑, kultur‑, literatur- und sozialgeschichtliches
            Kontextwissen gezielt für das Textverstehen nutzen
          </Bullet>
          <Bullet>
            mit handlungs- und produktionsorientierten Verfahren ein plausibles
            Textverständnis herausarbeiten, vertiefen und reflektiere
          </Bullet>
          <Bullet>
            den Zusammenhang zwischen Autor, Text und Rezipient reflektieren
          </Bullet>
          <Bullet>die Textinhalte mit eigenen Erfahrungen vergleichen</Bullet>
        </BulletList>
        <Paragraph>
          Die von den Schüler*innen eigenständig erarbeiteten Inhalte können in
          Form eines Unterrichtsgesprächs noch einmal aufgegriffen und abgefragt
          werden.
        </Paragraph>
        <Paragraph>
          Mögliche Impulse für eine Besprechung von Kapitel 1:
        </Paragraph>
        <BulletList size={[3, 3, 4, 5]}>
          <Bullet>
            In welcher Zeit lebte Friedrich Hölderlin? Wer waren seine
            Zeitgenossen?
          </Bullet>
          <Bullet>
            Welche Naturphänomene und Landschaftsbeschreibungen aus dem Gedicht
            sind euch besonders in Erinnerung geblieben?
          </Bullet>
          <Bullet>
            Welches Bild ergab sich bei euch beim Nachzeichnen der
            Bewegungsrichtungen des Gedichts?
          </Bullet>
          <Bullet>
            Wie erklärt ihr euch Hölderlins häufige Wechsel der Wohn- und
            Arbeitsorte?
          </Bullet>
          <Bullet>
            Wie macht sich Hölderlins Leidenschaft für das Wandern in seinen
            Werken bemerkbar?
          </Bullet>
        </BulletList>
        <Paragraph>
          Die entstandenen Werbetexte können im Klassenzimmer zu einer Pinnwand
          oder Litfaßsäule zusammengetragen werden.
        </Paragraph>
        <Paragraph>
          Mögliche Impulse für eine Besprechung von Kapitel 2:
        </Paragraph>
        <BulletList size={[3, 3, 4, 5]}>
          <Bullet>
            Was konnte man am Gedichtmanuskript über Hölderlins Schreib- und
            Arbeitsprozess erkennen?
          </Bullet>
          <Bullet>
            Mit welchen Mitteln und Kniffen hat Hölderlin an der Musikalität
            seines Gedichts gearbeitet?
          </Bullet>
          <Bullet>
            Welchen Eindruck hattet ihr von dem Gedichtvortrag des
            Sprechkünstlers Christian Reiner?
          </Bullet>
          <Bullet>
            Welche Erfahrung habt ihr beim Gehen und Sprechen des Gedichts
            gemacht?
          </Bullet>
        </BulletList>
        <Paragraph>
          Für die Präsentation der entstandenen filmischen Interpretationen des
          Gedichts kann das Klassenzimmer in ein Poetry-Film-Kino verwandelt
          werden.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
